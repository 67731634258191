import React, { useState, useEffect } from "react"
import css from "@styled-system/css"

import { Box, FlexGrid, Image } from "components"

const Post = ({ node }) => (
  <a
    data-component-id="instagramFeed.post"
    href="https://www.instagram.com/downtowncalgary/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image
      image={{ url: node.thumbnail_src, width: 512, height: 512 }}
      placeholder={<Box css={css({ width: "100%", pb: "100%", bg: "wash" })} />}
      appendQuery={false}
      css={{
        width: "100%",
        height: "auto",
        objectFit: "cover",
        transition: "all 200ms ease-out",
      }}
    />
  </a>
)

function InstagramFeed() {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    getInstagramPosts()
  }, [])

  const getInstagramPosts = () => {
    var xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        const pulledData = this.responseText
          .match(
            /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
          )[1]
          .slice(0, -1)
        const json = JSON.parse(pulledData)
        setPosts(
          json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.slice(
            0,
            6
          )
        )
      }
    }
    xhttp.open("GET", "https://www.instagram.com/downtowncalgary/", true)
    xhttp.send()
  }

  return (
    <Box data-component-id="instagramFeed" as="section" overflow="hidden">
      <FlexGrid
        gutterX={[0]}
        gutterY={[0]}
        content={posts.map((post, index) => {
          return {
            width: ["33.333333%", null, "16.666667%"],
            children: <Post {...post} key={index} />,
          }
        })}
      />
    </Box>
  )
}

export default InstagramFeed
